<template lang="pug">
  tr.matchings-item
    td.sticky-col.sync.align-top.pt-3
      AppToggle(
        :value="carClass.sync"
        :disabled="!isSelectedCarsAndShops"
        :title="carClass.sync ? $t('car_class_matching.sync_toggle_tooltip') : ''"
        v-b-tooltip.hover
        @change="changeCarClassData('sync', $event.value)"
      )
    td
      .item-name.h-100.pt-3(
        v-if="!isDraftMatching"
      )
        | {{ shop.name }}
      .item-name-dropdown.h-100(v-else)
        AppDropdown(
          z-index="103"
          searchable
          allow-empty
          close-on-select
          :placeholder="$t('shops_sources_car_matching.select_shop')"
          :value="localShop"
          :items="shops"
          @select="selectShop"
        )
    td.dp.align-top(v-if="currentOrganization.dp")
      AppCheckbox.highlight-checked(
        :value="carClass.dp"
        :disabled="!isSelectedCarsAndShops || !sourcesCar._addedAt"
        @change="changeCarClassDp($event)"
      )
    td.inbound.align-top(v-if="currentOrganization.inbound")
      AppCheckbox.highlight-checked(
        :value="carClass.inbound"
        :disabled="!isSelectedCarsAndShops"
        @change="changeCarClassData('inbound', $event)"
      )
    td.car-row.h-100.align-top
      .item-name.pt-3.pl-2(
        v-if="!isDraftMatching"
        :class="carStateStyle"
      )
        FaIcon.icon(
          v-if="carStateStyle"
          icon="exclamation-triangle"
        )
        | {{ sourcesCar.name }}
      .item-name-dropdown.h-100(v-else)
        AppDropdown(
          z-index="103"
          searchable
          close-on-select
          :disabled="!isSelectedShop"
          :placeholder="$t('shops_sources_car_matching.select_sources_car')"
          :value="sourcesCar"
          :is-item-disabled="denySelectSourcesCar"
          :items="availableCarsForLocalShop"
          @select="selectSourcesCar"
        )
      .car-description(v-if="isSourcesCarClassOpened && isSelectedShop && isSelectedCar")
        .items
          .car-item(:class="{disabled: !sourcesCar.smoking}")
            |   {{ $t('shops_sources_car_matching.smoking') }}
          .car-item(:class="{disabled: !sourcesCar.studless}")
            |   {{ $t('shops_sources_car_matching.studless') }}
          .car-item(:class="{disabled: !sourcesCar.hybrid}")
            |   {{ $t('shops_sources_car_matching.hybrid') }}
          .car-item(:class="{disabled: !sourcesCar.bluetooth}")
            |   {{ $t('shops_sources_car_matching.bluetooth') }}
          .car-item(:class="{disabled: !sourcesCar.drive != 'wd_4' }")
            |   {{ $t('shops_sources_car_matching.wd') }}
          .car-item(:class="{disabled: sourcesCar.transmission != 'at' }")
            |   {{ $t('shops_sources_car_matching.mission') }}
        .firstreg
          .label
            |   {{ $t('shops_sources_car_matching.firstreg') }}
          .value(:class="{ carwarning: sourcesCar.expired }")
            |   {{ carFirstRegistrationDate }}
        .warning
          | {{ sourcesCar.warning }}
    td.item-name-dropdown
      CarClassMatchingItem(
        :disabled="!isSelectedCarsAndShops"
        :item="carClass"
        :index="index"
        :is-sources-car-class-opened="isSourcesCarClassOpened"
        :ota-list="otasWithoutNicolas"
        :sources-items-by-ota-id="sourcesCarClassesByOtaId"
        :selected-sources-items-by-ota-id="selectedSourcesCarClasses"
        :update-item="selectSourcesCarClass"
        :add-selected-sources-item="addSelectedSourcesCarClass"
        :remove-selected-sources-item="removeSelectedSourcesCarClass"
        :immutable-ota-ids="immutableOtaIds"
        :invalid-item-ids="invalidItemIds"
        :sources-car-classes-loading="$emit('sources-car-classes-loading')"
        @change-name="changeCarClassData('name', $event.target.value)"
      )
    td
      shops-sources-actions(
        :local-shop="localShop"
        :is-sources-car-class-opened="isSourcesCarClassOpened"
        :is-selected-cars-and-shops="isSelectedCarsAndShops"
        :is-selected-car="isSelectedCar",
        :shops-deleted="shopsDeleted"
        :selected-shops="selectedShops",
        :shops-added="shopsAdded"
        :sources-car="sourcesCar"
        @toggle-car-class-open="toggleSourcesCarClassVisibility"
        @remove-sources-car="removeSourcesCarFromShop"
        @remove-sources-car-classes="removeSourcesCarClasses()"
      )
</template>

<script>
  import { currentTimestamp } from "@/config/store/StoreItemsModule/helpers"

  // mixins
  import withPermissions from "@/mixins/withPermissions"

  // misc
  import { mapGetters } from "vuex"
  import { findIndex, isEmpty, includes } from "lodash-es"

  export default {
    props: {
      shop: Object,
      shops: Array,
      selectedShops: {
        type: Array,
        required: true
      },
      shopsUpdated: Array,
      shopsAdded: Array,
      shopsDeleted: Array,
      addSelectedSourcesCarClass: Function,
      removeSelectedSourcesCarClass: Function,
      sourcesCarClassesByOtaId: Object,
      sourcesCar: Object,
      selectedSourcesCarClasses: {
        type: Object,
        required: true
      },
      addSelectedSourcesCar: Function,
      removeSelectedSourcesCar: Function,
      sourcesCars: Array,
      sourcesCarClassesLoading: Boolean,
      index: Number,
      invalidItemIds: {
        type: Array,
        default: () => new Array()
      }
    },

    data() {
      return {
        isSourcesCarClassOpened: false
      }
    },

    mixins: [withPermissions],

    components: {
      CarClassMatchingItem: () => import("./CarClassMatchingItem"),
      ShopsSourcesActions: () => import("./ShopsSourcesActions"),
      AppToggle: () => import("@/components/elements/AppToggle"),
      AppDropdown: () => import("@/components/elements/AppDropdown"),
      AppCheckbox: () => import("@/components/elements/AppCheckbox")
    },

    computed: {
      ...mapGetters(["otasWithoutNicolas", "currentOrganization"]),

      localShop() {
        return this.shop
      },

      selectedCars({ sourcesCars }) {
        return sourcesCars.filter(car => !!car.shop_id)
      },

      availableCars({ sourcesCars }) {
        return sourcesCars.filter(car => !car.shop_id)
      },

      availableCarsForLocalShop({ sourcesCars, localShop }) {
        return sourcesCars.filter(car => includes(localShop.external_ids, car.shop_code))
      },

      carClass() {
        return this.sourcesCar.car_class
      },

      immutableOtaIds() {
        return this.carClass.immutable_ota_ids
      },

      isDraftMatching() {
        return this.localShop.sources_cars[0]._addedAt
      },

      isSelectedCar() {
        return !isEmpty(this.sourcesCar) && Boolean(this.sourcesCar.id)
      },

      isSelectedShop() {
        return Boolean(this.localShop.id)
      },

      isSelectedCarsAndShops() {
        return this.isSelectedCar && this.isSelectedShop
      },

      carStateStyle() {
        if (!this.sourcesCar.active) return "crossout"
        if (this.sourcesCar.expired) return "carwarning"

        return null
      },

      carFirstRegistrationDate() {
        if (isEmpty(this.sourcesCar.first_registration)) return null

        const [year, month] = this.sourcesCar.first_registration.split("/")

        return this.$t("shops_sources_car_matching.firstreg_value", { year: year, month: month })
      }
    },

    methods: {
      selectShop(value) {
        if (value.id === this.localShop.id) return

        if (this.isSelectedCar) {
          this.removeSourcesCarFromShop(this.sourcesCar)
          this.removeSourcesCarClasses()
        }

        this.isSourcesCarClassOpened = false
        this.updateLocalShop(value)
      },

      updateLocalShop(value) {
        this.localShop.id = value.id
        this.localShop.name = value.name
        this.localShop.dp = value.dp
        this.localShop.sync = value.sync
        this.localShop.external_ids = value.external_ids
        this.localShop.sources_cars = [...this.defaultSourcesCarsAttrs()]

        this.$emit("update-shop", this.localShop, this.index)
      },

      denySelectSourcesCar({ id }) {
        const selectedCarIds = this.selectedCars.map(car => car.id)

        return selectedCarIds.includes(id)
      },

      selectSourcesCarClass(item) {
        const index = findIndex(this.carClass.sources_car_classes, { ota_id: item.ota_id })

        if (index !== -1) {
          const oldSourcesCarClass = this.carClass.sources_car_classes[index]
          const oldIndex = this.selectedSourcesCarClasses[item.ota_id].indexOf(oldSourcesCarClass.id)

          if (item.id === oldSourcesCarClass.id) {
            this.removeSourcesCarClass(item, oldIndex, index)
          } else {
            this.changeSourcesCarClass(item, oldIndex, index)
          }
        } else {
          this.addSourcesCarClass(item)
        }

        this.markShopAsUpdated()
      },

      addSourcesCarClass(item) {
        this.carClass.sources_car_classes.push(item)
        this.selectedSourcesCarClasses[item.ota_id].push(item.id)
      },

      changeSourcesCarClass(item, oldIndex, index) {
        this.selectedSourcesCarClasses[item.ota_id].splice(oldIndex, 1, item.id)
        this.carClass.sources_car_classes.splice(index, 1, item)
      },

      removeSourcesCarClasses() {
        this.carClass.sources_car_classes.forEach(sourcesCarClass => {
          const selectedSCCIndex = this.selectedSourcesCarClasses[sourcesCarClass.ota_id].indexOf(sourcesCarClass.id)

          this.selectedSourcesCarClasses[sourcesCarClass.ota_id].splice(selectedSCCIndex, 1)
        })
      },

      removeSourcesCarClass(item, oldIndex, index) {
        this.selectedSourcesCarClasses[item.ota_id].splice(oldIndex, 1)
        this.carClass.sources_car_classes.splice(index, 1)
      },

      selectSourcesCar(sourcesCar) {
        const previosSelectedCar = this.localShop.sources_cars.find(car => car.shopUniqKey == this.localShop._addedAt)

        if (previosSelectedCar?.id === sourcesCar.id) return

        if (!isEmpty(previosSelectedCar)) {
          this.removeSourcesCarFromShop(previosSelectedCar)
        }

        this.removeSourcesCarClasses()
        this.addSourcesCarToShop(sourcesCar, previosSelectedCar)
      },

      addSourcesCarToShop(selectedCar, previosSelectedCar) {
        selectedCar.shop_id = this.localShop.id
        selectedCar._addedAt = `t_${currentTimestamp()}`
        selectedCar.shopUniqKey = this.localShop._addedAt
        selectedCar.car_class = { ...this.defaultCarClassAttrs() }

        if (isEmpty(previosSelectedCar)) {
          this.localShop.sources_cars.push({ ...selectedCar })
          this.localShop.sources_cars.splice(findIndex(this.localShop.sources_cars, { id: null }), 1)
        } else {
          const carIndexInShop = findIndex(this.localShop.sources_cars, { id: selectedCar.id })
          this.localShop.sources_cars.splice(carIndexInShop, 1, selectedCar)
        }
      },

      removeSourcesCarFromShop(sourceCar) {
        const carIndex = findIndex(this.sourcesCars, { id: sourceCar.id })

        this.$set(this.sourcesCars, carIndex, {
          ...this.sourcesCars[carIndex],
          shop_id: null,
          _addedAt: null,
          car_class: { ...this.defaultCarClassAttrs() }
        })
      },

      defaultCarClassAttrs() {
        return {
          sources_car_classes: [],
          dp: false,
          name: null,
          inbound: false,
          immutable_ota_ids: [],
          sync: false
        }
      },

      defaultSourcesCarsAttrs() {
        return [
          {
            id: null,
            name: "",
            car_class: {
              sources_car_classes: [],
              dp: false,
              name: null,
              inbound: false,
              immutable_ota_ids: [],
              sync: false
            },
            _addedAt: `t_${currentTimestamp()}`
          }
        ]
      },

      changeCarClassDp(value) {
        this.carClass.dp = value
        this.resetSourcesCarClasses()
      },

      changeCarClassData(field, value) {
        this.carClass[field] = value
        this.markShopAsUpdated()
      },

      markShopAsUpdated() {
        if (this.carClass.id && !includes(this.shopsUpdated, this.localShop)) {
          this.shopsUpdated.push(this.localShop)
        }
      },

      resetSourcesCarClasses() {
        if (!isEmpty(this.carClass.sources_car_classes)) {
          this.removeSourcesCarClasses()
          this.carClass.sources_car_classes = []
        }
      },

      toggleSourcesCarClassVisibility() {
        this.isSourcesCarClassOpened = !this.isSourcesCarClassOpened
      }
    }
  }
</script>

<style lang="sass" scoped>
  @import "@/assets/styles/variables.sass"
  @import "@/assets/styles/matchings.sass"

  .carwarning
    color: #DC3545
  .crossout
    color: #DC3545
    text-decoration: line-through
  .car-row
    width: 22em
  .car-description
    display: flex
    flex-wrap: wrap
    margin: 5px
  .firstreg
    width: 100%
    display: flex
    justify-content: space-between
    border: 1px solid lightgray
    margin: 5px
    .label
      width: 100%
      text-align: center
      background: #E9EFF4
    .value
      width: 100%
      text-align: center
  .warning
    margin: 5px
  .items
    display: flex
    width: 100%
    justify-content: space-around
    margin-bottom: 0.5em
  .car-item
    text-align: center
    font-weight: bold
    color: white
    height: 30px
    width: 36px
    font-size: 10px
    padding: 1px
    display: flex
    align-items: center
    justify-content: center
    background: #5965E7
    border-radius: 3px

    &.disabled
      background: #E5E7FC

  .matchings-item
    height: 50px

    td
      +matchings-item-td

      &.item-name
        width: 30%

      &.selected-quantity
        width: 6%

      &.dp, &.inbound
        padding-top: 0.75rem
</style>
